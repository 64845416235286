import { FilmComponent } from "../FilmComponent/FilmComponent"
import { GalleryComponent2 } from "../Gallery/GalleryComponent2"
import { marchPhotos } from "../Gallery/LabPhotos/march/marchPhotos"
import video1 from "../../assets/labPhotos/march/video1.mp4"

import "./LabText.scss"

export const March = () => {
	return (
		<div className='march'>
			<h2 className='LabTitle'>marzec</h2>
			<p className='LabText2'>
				W tym miesiącu klasa szósta uczyła się obsługi lutownicy. Uczniowie
				klasy czwartej wykonywali witraże, które później zapełniali farbami,
				powstały piękne dekoracje klasowe. Klasa siódma i ósma oraz czwarta
				posługiwała się sprzętem do nagrań- wykonali inscenizację oraz nagrywali
				etiudę – swoją pracę oceniali oglądając filmy i zdjęcia.
			</p>
			<p className='LabText2'>
				Drukarka 3D wykorzystana została do wykonania atrybutów mitycznych
				bogów, następnie uczniowie brali udział w zabawie :”Jakim jestem
				bogiem?”.
			</p>
			<p className='LabText2'>
				Dzięki posiadaniu drukarki 3D, mieliśmy możliwość wydrukowania
				podstawowych brył: sześcianu, prostopadłościanu, które stanowiły
				doskonała pomoc na lekcjach matematyki. Często wykorzystywaliśmy aparat
				zwłaszcza przy wykonywaniu ciekawych prac świątecznych, dodatkową
				przyjemność stanowi samodzielne wykonywanie zdjęć, czy nagrywanie
				filmów. Na zajęciach z języka angielskiego uczniowie klasy pierwszej i
				drugiej budowali zwierzęta z klocków Lego i je nazywali, opisywali też w
				jakim są kolorze i gdzie żyją.
			</p>
			<GalleryComponent2 carouselId='marchPhotos5' images={marchPhotos} />
			<FilmComponent videoPath={video1} />
		</div>
	)
}
