import { FilmComponent } from "../FilmComponent/FilmComponent"
import { GalleryComponent2 } from "../Gallery/GalleryComponent2"
import { aprilPhotos } from "../Gallery/LabPhotos/april/aprilPhotos"
import video1 from "../../assets/labPhotos/april/video1.mp4"

import "./LabText.scss"

export const April = () => {
	return (
		<div className='april'>
			<h2 className='LabTitle'>kwiecień</h2>
			<p className='LabText2'>
				Nasi uczniowie brali udział w XXII Mini Liście Przebojów zorganizowanej
				przez Specjalny Ośrodek Szkolno- Wychowawczy. Tam wykorzystano sprzęt do
				nagrywania i robienia zdjęć. Uczniowie klasy piątej korzystając z
				klocków Modi wprawiali w ruch swoje pojazdy, poznawali zasadę działania
				żyroskopu, uruchamiali latarkę. Na zajęciach z rewalidacji wykorzystano
				piankolinę, dzieci robiły zdjęcia swoich prac. Uczniowie klasy siódmej
				na lekcjach języka niemieckiego nagrywali scenki : „W restauracji” –
				dialog między kelnerem a klientem składającym zamówieniem. Uczniowie
				analizowali swoje wystąpienia, wskazywali co można poprawić lub zmienić.
			</p>
			<p className='LabText2'>
				Korzystaliśmy z długopisów 3D realizując tematykę segregacji śmieci.
			</p>
			<GalleryComponent2 carouselId='aprilPhotos' images={aprilPhotos} />
			<FilmComponent videoPath={video1} />
		</div>
	)
}
