import photo1 from "../../../../assets/labPhotos/october/IMG1.jpg"
import photo2 from "../../../../assets/labPhotos/october/IMG2.jpg"
import photo3 from "../../../../assets/labPhotos/october/IMG3.jpg"
import photo4 from "../../../../assets/labPhotos/october/IMG4.jpg"
import photo5 from "../../../../assets/labPhotos/october/IMG5.jpg"
import photo6 from "../../../../assets/labPhotos/october/IMG6.jpg"
import photo7 from "../../../../assets/labPhotos/october/IMG7.jpg"
import photo8 from "../../../../assets/labPhotos/october/IMG8.jpg"
import photo9 from "../../../../assets/labPhotos/october/IMG9.jpg"
import photo10 from "../../../../assets/labPhotos/october/IMG10.jpg"

export const octoberPhotos2 = [
	{ srcSmall: photo1, alt: "Photo 1" },
	{ srcSmall: photo2, alt: "Photo 2" },
	{ srcSmall: photo3, alt: "Photo 3" },
	{ srcSmall: photo4, alt: "Photo 4" },
	{ srcSmall: photo5, alt: "Photo 5" },
	{ srcSmall: photo6, alt: "Photo 6" },
	{ srcSmall: photo7, alt: "Photo 7" },
	{ srcSmall: photo8, alt: "Photo 8" },
	{ srcSmall: photo9, alt: "Photo 9" },
	{ srcSmall: photo10, alt: "Photo 10" },
]
