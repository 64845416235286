import photo11 from "../../../../assets/labPhotos/october/IMG11.jpg"
import photo12 from "../../../../assets/labPhotos/october/IMG12.jpg"
import photo13 from "../../../../assets/labPhotos/october/IMG13.jpg"
import photo14 from "../../../../assets/labPhotos/october/IMG14.jpg"
import photo15 from "../../../../assets/labPhotos/october/IMG15.jpg"
import photo16 from "../../../../assets/labPhotos/october/IMG16.jpg"
import photo17 from "../../../../assets/labPhotos/october/IMG17.jpg"
import photo18 from "../../../../assets/labPhotos/october/IMG18.jpg"
import photo19 from "../../../../assets/labPhotos/october/IMG19.jpg"

export const octoberPhotos3 = [
	{ srcSmall: photo11, alt: "Photo 11" },
	{ srcSmall: photo12, alt: "Photo 12" },
	{ srcSmall: photo13, alt: "Photo 13" },
	{ srcSmall: photo14, alt: "Photo 14" },
	{ srcSmall: photo15, alt: "Photo 15" },
	{ srcSmall: photo16, alt: "Photo 16" },
	{ srcSmall: photo17, alt: "Photo 17" },
	{ srcSmall: photo18, alt: "Photo 18" },
	{ srcSmall: photo19, alt: "Photo 19" },
]
