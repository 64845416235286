import photo1 from "../../assets/gallery/photo1.jpg"
import photo2 from "../../assets/gallery/photo2.jpg"
import photo3 from "../../assets/gallery/photo3.jpg"
import photo4 from "../../assets/gallery/photo4.jpg"

export const homePhotos = [
  { srcSmall: photo1, alt: "Photo 1" },
  { srcSmall: photo2, alt: "Photo 2" },
  { srcSmall: photo3, alt: "Photo 3" },
  { srcSmall: photo4, alt: "Photo 4" },
]