import photo1 from "../../../../assets/labPhotos/june/photo1.jpg"
import photo2 from "../../../../assets/labPhotos/june/photo2.jpg"
import photo3 from "../../../../assets/labPhotos/june/photo3.jpg"
import photo4 from "../../../../assets/labPhotos/june/photo4.jpg"
import photo5 from "../../../../assets/labPhotos/june/photo5.jpg"
import photo6 from "../../../../assets/labPhotos/june/photo6.jpg"
import photo7 from "../../../../assets/labPhotos/june/photo7.jpg"
import photo8 from "../../../../assets/labPhotos/june/photo8.jpg"
import photo9 from "../../../../assets/labPhotos/june/photo9.jpg"
import photo10 from "../../../../assets/labPhotos/june/photo10.jpg"
import photo11 from "../../../../assets/labPhotos/june/photo11.jpg"
import photo12 from "../../../../assets/labPhotos/june/photo12.jpg"
import photo13 from "../../../../assets/labPhotos/june/photo13.jpg"
import photo14 from "../../../../assets/labPhotos/june/photo14.jpg"
import photo15 from "../../../../assets/labPhotos/june/photo15.jpg"
import photo16 from "../../../../assets/labPhotos/june/photo16.jpg"
import photo17 from "../../../../assets/labPhotos/june/photo17.jpg"
import photo18 from "../../../../assets/labPhotos/june/photo18.jpg"
import photo19 from "../../../../assets/labPhotos/june/photo19.jpg"
import photo20 from "../../../../assets/labPhotos/june/photo20.jpg"

export const junePhotos = [
	{ srcSmall: photo1, alt: "Photo 1" },
	{ srcSmall: photo2, alt: "Photo 2" },
	{ srcSmall: photo3, alt: "Photo 3" },
	{ srcSmall: photo4, alt: "Photo 4" },
	{ srcSmall: photo5, alt: "Photo 5" },
	{ srcSmall: photo6, alt: "Photo 6" },
	{ srcSmall: photo7, alt: "Photo 7" },
	{ srcSmall: photo8, alt: "Photo 8" },
	{ srcSmall: photo9, alt: "Photo 9" },
	{ srcSmall: photo10, alt: "Photo 10" },
	{ srcSmall: photo11, alt: "Photo 11" },
	{ srcSmall: photo12, alt: "Photo 12" },
	{ srcSmall: photo13, alt: "Photo 13" },
	{ srcSmall: photo14, alt: "Photo 14" },
	{ srcSmall: photo15, alt: "Photo 15" },
	{ srcSmall: photo16, alt: "Photo 16" },
	{ srcSmall: photo17, alt: "Photo 17" },
	{ srcSmall: photo18, alt: "Photo 18" },
	{ srcSmall: photo19, alt: "Photo 19" },
	{ srcSmall: photo20, alt: "Photo 20" },
]
