import React from 'react';
import './PopUp.scss';

export const PopUp = ({ message, closePopup }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <span className="close-btn" onClick={closePopup}>
          &times;
        </span>
        <p>{message}</p>
      </div>
    </div>
  );
};
