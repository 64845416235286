export const ContactDataComponent = () => {
	return (
		<>
			<h2 className='title-contact'>Dane kontaktowe:</h2>
			<div className='data-contact'>
				<p>
					<strong>adres:</strong>
					<br />
					ul. Garncarska 3
					<br />
					58-200 Dzierżoniów
				</p>
				<p>
					<strong>e-mail:</strong>
					<br />
					szkola.korczak@wp.pl
				</p>
				<p>
					<strong>telefon:</strong>
					<br />
					600 904 492 lub 74 832 36 88
				</p>
			</div>
		</>
	)
}
