import photo1 from "../../../../assets/labPhotos/september/photo1.jpg"
import photo2 from "../../../../assets/labPhotos/september/photo2.jpg"
import photo3 from "../../../../assets/labPhotos/september/photo3.jpg"
import photo4 from "../../../../assets/labPhotos/september/photo4.jpg"
import photo5 from "../../../../assets/labPhotos/september/photo5.jpg"
import photo6 from "../../../../assets/labPhotos/september/photo6.jpg"
import photo7 from "../../../../assets/labPhotos/september/photo7.jpg"

export const septemberPhotos = [
  { srcSmall: photo1, alt: "Photo 1" },
  { srcSmall: photo2, alt: "Photo 2" },
  { srcSmall: photo3, alt: "Photo 3" },
  { srcSmall: photo4, alt: "Photo 4" },
  { srcSmall: photo5, alt: "Photo 5" },
  { srcSmall: photo6, alt: "Photo 6" },
  { srcSmall: photo7, alt: "Photo 7" },
]