import photo5 from "../../../../assets/labPhotos/november/photo5.jpg"
import photo6 from "../../../../assets/labPhotos/november/photo6.jpg"
import photo7 from "../../../../assets/labPhotos/november/photo7.jpg"
import photo8 from "../../../../assets/labPhotos/november/photo8.jpg"
import photo9 from "../../../../assets/labPhotos/november/photo9.jpg"
import photo10 from "../../../../assets/labPhotos/november/photo10.jpg"
import photo11 from "../../../../assets/labPhotos/november/photo11.jpg"
import photo12 from "../../../../assets/labPhotos/november/photo12.jpg"

export const novemberPhotos2 = [
	{ srcSmall: photo5, alt: "Photo 5" },
	{ srcSmall: photo6, alt: "Photo 6" },
	{ srcSmall: photo7, alt: "Photo 7" },
	{ srcSmall: photo8, alt: "Photo 8" },
	{ srcSmall: photo9, alt: "Photo 9" },
	{ srcSmall: photo10, alt: "Photo 10" },
	{ srcSmall: photo11, alt: "Photo 11" },
	{ srcSmall: photo12, alt: "Photo 12" },
]
